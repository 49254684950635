.dashboard-wrapper {
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .dashboard-container {
    height: 93%;
    width: 96%;
    margin: 20px 0px 20px 20px;
    background-color: #f5f5f5;
    .one {
      height: 70px;
      width: 100%;
      background-color: #f5f5f5;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        .greeting {
          font-weight: 600;
          font-size: 24px;
          line-height: 32.69px;
          color: #262626;
        }
        .user-name {
          font-weight: 600;
          font-size: 24px;
          line-height: 32.69px;
          color: #2d5fa1;
        }
      }
    }
    .two {
      :hover {
          background-color: lightgrey;
      }
      margin-top: 20px;
      width: 100%;
      height: 130px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
    .three {
      margin-top: 20px;
      width: 100%;
      height: 463px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .activity-analytics {
        width: 70%;
        height: 100%;
        background-color: white;
        border-radius: 8px;
      }
      .did-you-know {
        width: 30%;
        height: 100%;
        background-color: white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .dyk-wrapper {
          position: relative;
          overflow: hidden;
          height: 90%;
          width: 90%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .slider-container-dyk {
            bottom: 20px;
            left: 5%;
            position: absolute;
            width: 90%;
            // height: 169px;
            height: 120px;
            display: flex;
            flex-direction: column;
            background-color: white;
            .navigation-button {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 10px;
              padding-right: 15px;
              padding-bottom: 5px;
              img {
                width: 30px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .five {
      margin-top: 20px;
      width: 100%;
      height: 206px;
      background-color: #f5f5f5;
      display: flex;
      gap: 20px;
    }
    .six {
      margin-top: 20px;
      width: 100%;
      height: 230px;
      .short-story-slider {
        border-radius: 8px;
        background-color: white;
        width: 100%;
        height: 150%;
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        .short-story-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 20px;
          h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 27.24px;
            color: #272828;
          }
          .handle-navigation-button {
            width: 100px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .dashboard-container {
      height: 93%;
      width: 96%;
      margin: 20px 0px 20px 20px;
      background-color: #f5f5f5;
      .one {
        height: 70px;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          .greeting {
            font-weight: 600;
            font-size: 24px;
            line-height: 32.69px;
            color: #262626;
          }
          .user-name {
            font-weight: 600;
            font-size: 24px;
            line-height: 32.69px;
            color: #2d5fa1;
          }
        }
      }
      .three-cut{
        margin-top: 20px;
        width: 100%;
        height: 210px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        border-radius: 8px;
        .did-you-know {
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          .dyk-wrapper {
            position: relative;
            overflow: hidden;
            height: 100%;
            width: 100%;
            img {
              width: 40%;
              height: 100%;
              object-fit: cover;
            }
            .slider-container-dyk {
              bottom: 20px;
              left: 38%;
              position: absolute;
              width: 50%;
              height: 169px;
              display: flex;
              flex-direction: column;
              background-color: white;
              // margin-left: 150px;
              .navigation-button {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 330px;
                // padding-right: 10px;
                // padding-bottom: 5px;
                margin-top: 70px;
                margin-left: -150px;
                position: absolute;
                z-index: 1;
                img {
                  width: 30px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .two {
        :hover {
            background-color: lightgrey;
        }
        margin-top: 20px;
        width: 100%;
        height: 130px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
      }
      .three {
        margin-top: 20px;
        width: 100%;
        height: 463px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .activity-analytics {
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 8px;
        }
        // .did-you-know {
        //   width: 30%;
        //   height: 100%;
        //   background-color: white;
        //   border-radius: 8px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   .dyk-wrapper {
        //     position: relative;
        //     overflow: hidden;
        //     height: 90%;
        //     width: 90%;
        //     img {
        //       width: 100%;
        //       height: 100%;
        //       object-fit: cover;
        //     }
        //     .slider-container-dyk {
        //       bottom: 20px;
        //       left: 5%;
        //       position: absolute;
        //       width: 90%;
        //       height: 169px;
        //       display: flex;
        //       flex-direction: column;
        //       background-color: white;
        //       .navigation-button {
        //         display: flex;
        //         align-items: center;
        //         justify-content: flex-end;
        //         gap: 10px;
        //         padding-right: 15px;
        //         padding-bottom: 5px;
        //         img {
        //           width: 30px;
        //           cursor: pointer;
        //         }
        //       }
        //     }
        //   }
        // }
      }
      .five {
        margin-top: 20px;
        width: 100%;
        height: 206px;
        background-color: #f5f5f5;
        display: flex;
        gap: 20px;
      }
      .six {
        margin-top: 20px;
        width: 100%;
        height: 230px;
        .six-1 {
          width: 100%;
          height: 155px;
        
        .short-story-slider {
          border-radius: 8px;
          background-color: white;
          width: 100%;
          height: 150%;
          display: flex;
          justify-content: center;
          // align-items: center;
          flex-direction: column;
          .short-story-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px;
            h3 {
              font-weight: 500;
              font-size: 20px;
              line-height: 27.24px;
              color: #272828;
            }
            .handle-navigation-button {
              width: 100px;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              img {
                cursor: pointer;
              }
            }
          }
        }
      }
      }
    }
  }
}

//swaper style--

// .swiper {
//   width: 100%;
//   height: 100%;
// }
// .swiper-slide {
//   /* Center slide text vertically */
//   display: flex;
//   justify-content: center;
//   // gap: 20px;
//   // align-items: center;
//   &.swiper-slide-active {
//     display: flex;
//     justify-content: flex-end;
//     margin-right: 20px;
//   }
//   &.swiper-slide-next {
//     display: flex;
//     justify-content: flex-start;
//     margin-right: 20px;
//   }
// }
// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
// .swiper-pagination-horizontal {
//   top: 180px !important;
//   left: 10px !important;
// }

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MuiButton-textSizeMedium{
  width: 100% !important;
}

.MuiButton-outlined {
  border-color: black !important;
}
