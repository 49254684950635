.navbar-wrapper {
  width: 100vw;
  background-color: #ffffff;
  height: 91px;
  display: flex;
  .left {
    flex: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      margin-left: 20px;
      cursor: pointer;
    }
  } 
  .right {
    flex: 2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    button {
      background-color: #d74f58;
      height: 46px;
      width: 144px;
      border-radius: 8px;
      border: none;
      outline: none;
      font-size: 16px;
      line-height: 21.79px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .navbar-profile {
      cursor: pointer;
      height: 50px;
      width: 230px;
      display: flex;
      .one {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .two {
        flex: 4;
        display: flex;
        flex-direction: column;
        .two-top {
          display: flex;
          align-items: center;
          flex: 1;
          p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 21.79px;
            color: #d74f58;
          }
        }
        .two-bottom {
          flex: 1;
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.07px;
            margin: 0;
            padding: 0;
            color: #4d4d4d;
          }
        }
      }
      .three {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .navbar-wrapper {
    // height: 30vw;
    width: 100vw;
    background-color: #ffffff;
    // height: 91px;
    display: flex;
    flex-direction: column;
    .left {
      flex: 3;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-left: 20px;
        cursor: pointer;
        width: 109px;
        height: 29px;
        padding: 6px 12px 6px 12px;


      }
    } 
    .right {
      flex: 2;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      button {
        background-color: #d74f58;
        height: 40px;
        width: 107px;
        border-radius: 8px;
        border: none;
        outline: none;
        font-size: 16px;
        line-height: 21.79px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        margin-left: 280px;
        margin-top: 5px;
      }
      .navbar-profile {
        cursor: pointer;
        height: 50px;
        width: 230px;
        display: flex;
        margin-top: 2px;
        .one {
          // flex: 2;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          width: 107px;
          height: 80px;
          margin-top: -107px;
          margin-left: 240px;
        }
        .two {
          flex: 4;
          display: flex;
          flex-direction: column;
          .two-top {
            display: flex;
            align-items: center;
            flex: 1;
            p {
              margin: 0;
              padding: 0;
              font-size: 16px;
              font-weight: 600;
              line-height: 21.79px;
              // color: #d74f58;
              color: black;
              margin-left: -300px;
              margin-top: -110px;
            }
          }
          .two-bottom {
            flex: 1;
            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 19.07px;
              margin: 0;
              padding: 0;
              color: #4d4d4d;
            }
          }
        }
        // .three {
        //   flex: 1;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
      }
    }
  }
  
}

.mini-sidebar-container {
  width: 250px;
  // height: 230px;
  height: 280px;
  position: fixed;
  right: 20px;
  top: 90px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  outline: none;
  .mini-sidebar-wrapper {
    width: 90%;
    height: 90%;
    .ms-top {
      width: 100%;
      height: 80px;
      display: flex;
      .ms-left {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .ms-right {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h3 {
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        p {
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #888888;
        }
      }
    }
    hr {
      width: 100%;
      color: #888888;
      opacity: 0.3;
    }
    .p-setting {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #575757;
      cursor: pointer;
    }
    .signout {
      font-weight: 400;
      line-height: 22px;
      font-size: 14px;
      color: #d74f58;
      cursor: pointer;
    }
  }
}
