.frameParent {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .job-details {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    .jd-top {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #7a7a7a;
      }
    }
    .jd-bottom {
      width: 100%;
      height: 60%;
      display: flex;
      .job-label-icon {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        img {
        }
        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 18px;
          color: #262626;
        }
      }
      .percentage-icon {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        img {
          width: 10px;
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #23b04a;
        }
      }
    }
  }
}
