.layout {
  // position: relative;
  width: 100vw;
  height: 100vh;
  .navbar-container {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
  }
  .layout-wrapper {
    position: absolute;
    top: 91px;
    height: calc(100vh - 91px);
    display: flex;
    .sidebar-container {
      position: fixed;
      left: 0;
      width: 195px;
      height: calc(100vh - 91px);
      overflow-y: scroll;
      background-color: #00468a;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .outlet-container {
      margin-left: 195px;
      width: calc(100vw - 195px);
      background-color: #f5f5f5;
    }
  }

  @media only screen and (max-width: 480px) {
    .layout-wrapper {
      position: absolute;
      top: 91px;
      // height: calc(100vh - 91px);
      height: 100%;
      // display: flex;
      .sidebar-container {
        position: fixed;
        // left: 0;
        width: 100%;
        // height: calc(100vh - 91px);
        height: 50px;
        // overflow-y: scroll;
        background-color: #00468a;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .outlet-container {
        // top: 250px;
        margin-left: -5px;
        margin-top: 50px;
        // margin-left: 195px;
        // width: calc(100vw - 195px);
        width: 100%;
        // overflow-x: fixed;
        background-color: #f5f5f5;
      }
    }
  }


}
